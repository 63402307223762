import React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import { Disclosure } from "@headlessui/react"
import { ChevronDownIcon } from "@heroicons/react/outline"
import ContactSection from "../components/contactSection"
import Divider from "../components/divider"
import InstagramFeed from "../components/instagramFeed"

const faqs = [{
  category: "Allgemein",
  description: "",
  questions: [
    {
      question: "Wie wählt Caffeinity den passenden Kaffee aus?",
      answer:
        "Wir haben einen Fragebogen entwickelt welcher nach deiner Zubereitungsart zu Hause sowie deinen Geschmacklichen Präferenzen fragt. Anhand dieser Informationen und unseren Kaffees die wir alle verkostet haben können wir den richtigen Kaffee für dich auswählen. Probier es gleich selbst aus und erstelle hier dein Geschmacksprofil."
    },
    {
      question: "Was mache ich wenn mir der Kaffee nicht schmeckt?",
      answer:
        "Falls dir der von uns zugesendete Kaffee nicht schmeckt, melde dich bitte bei caffeinity.vienna@gmail.com und wir finden eine individuelle Lösung für dich."
    },
    {
      question: "Ich weiß nicht, wie ich meinen Kaffee am Besten zubereiten soll.",
      answer:
        "Alles kein Problem! Wir haben genügend Informationsmaterial wie du deinen Kaffee am besten bei dir zu Hause zubereitest."
    },
    {
      question: "Für welche Kaffeemaschinen ist Caffeinity geeignet?",
      answer:
        "Für unseren Kaffee benötigst du keine spezielle Kaffeemaschine. Wir bieten Kaffeebohnen für Siebträger, Vollautomaten, Filter, Bialetti / Mokka Kanne, Frenshpress, Aeropress, V60 und Chemex an."
    }

  ]
},
  {
    category: "Speciality Kaffee",
    description: "In dieser Kategorie behandeln wir Fragen rund um das Thema Specialty Coffee – hochwertiger Kaffee, geprägt durch außergewöhnliche Anbau- und Verarbeitungsmethoden. Von speziellem Anbau bis zur präzisen Röstung hebt sich Specialty Coffee durch Qualität ab. Direct Trade fördert direkte Zusammenarbeit für Transparenz und bessere Bedingungen. Optimale Lagerung bis hin zu Aromamaximierung und Aromavielfalt von fruchtig bis schokoladig. Willkommen in der Welt des exquisiten Kaffeegenusses!",
    questions: [
      {
        question: "Was ist Specialty Coffee?",
        answer: "Specialty Coffee bezieht sich auf qualitativ hochwertigen Kaffee, der aufgrund außergewöhnlicher Anbau- und Verarbeitungsmethoden eine überdurchschnittliche Geschmacksqualität aufweist. Er wird oft von kleinen Farmen produziert und durch eine nachhaltige Lieferkette unterstützt."
      },
      {
        question: "Wie unterscheidet sich Specialty Coffee von gewöhnlichem Kaffee?",
        answer: "Specialty Coffee zeichnet sich durch spezielle Anbaumethoden, sorgfältige Ernte, hochwertige Bohnen und eine präzise Röstung aus. Im Gegensatz dazu werden herkömmliche Kaffeesorten oft in großen Mengen produziert, ohne die gleiche Aufmerksamkeit für Qualität."
      },
      {
        question: "Welche Kriterien machen einen Kaffee zu Specialty Coffee?",
        answer: "Specialty Coffee wird anhand verschiedener Kriterien bewertet, darunter Anbauhöhe, Bohnenqualität, Ernte- und Verarbeitungsmethoden. Eine Gesamtpunktzahl von 80 oder höher auf einer 100-Punkte-Skala wird oft als Indikator für Specialty Coffee betrachtet."
      },
      {
        question: "Was bedeutet Direct Trade bei Specialty Coffee?",
        answer: "Direct Trade bezieht sich darauf, dass Kaffeebauern direkt mit Röstern oder Händlern zusammenarbeiten, ohne Zwischenhändler. Dies ermöglicht eine transparentere Lieferkette und bessere Arbeitsbedingungen für die Bauern."
      },
      {
        question: "Specialty Coffee sollte an einem kühlen, trockenen Ort in einem luftdichten Behälter aufbewahrt werden, um Frische und Aromen zu bewahren.",
        answer: "Specialty Coffee bietet oft eine breitere Palette von Aromen, die von fruchtig und blumig bis zu nussig und schokoladig reichen. Der Geschmack kann subtiler und komplexer sein als bei gewöhnlichem Kaffee."
      },
      {
        question: "Wie soll ich meinen Kaffee am besten Lagern?",
        answer: "Specialty Coffee sollte an einem kühlen, trockenen Ort in einem luftdichten Behälter aufbewahrt werden, um Frische und Aromen zu bewahren. Am einfachsten ist es deinen Kaffee im Tiefkühler zu lagern und dann immer nur die benötigte Menge zu entnehmen."
      }
    ]
  },
  {
    category: "Kaffee Zubereitung",
    description: "Die Zubereitung von Specialty Coffee ist eine Kunst, die Aufmerksamkeit für Details erfordert, um die feinen Aromen und Nuancen herauszubringen. Von der Auswahl der Bohnen bis zur Extraktion gibt es viele Faktoren, die den Geschmack beeinflussen. ",
    questions: [
      {
        question: "Welche Art von Kaffeebohnen sollte ich für Specialty Coffee verwenden?",
        answer: "Wähle hochwertige, frische Kaffeebohnen aus renommierten Anbaugebieten. Die Qualität der Bohnen beeinflusst den Geschmack erheblich."
      },
      {
        question: "Ist es wichtig, die Kaffeebohnen frisch zu mahlen?",
        answer: "Ja, das Mahlen der Bohnen unmittelbar vor der Zubereitung erhöht das Aroma und die Frische des Kaffees. Gemahlener Kaffee verliert nach 10 minuten die Hälfte seines Aromas."
      },
      {
        question: "Wie wähle ich den richtigen Mahlgrad für meine Zubereitungsmethode aus?",
        answer: "Der Mahlgrad variiert je nach Zubereitungsmethode. Feiner Mahlgrad eignet sich beispielsweise für Espresso, während für die French Press ein grober Mahlgrad empfohlen wird. Du musst den Mahlgrad anhand unserer Gundreztep annäherungsweise einstellen, d.h. Kaffee zubereiten, Parameter messen, verkosten und dann adaptieren."
      },
      {
        question: "Wie wichtig ist die richtige Wassertemperatur?",
        answer: "Die Wassertemperatur hängt auch wieder vond er Zubereitungsmethode ab. Am Anfang solltest du noch kein Augenmerk auf diese legen. Allgemein befinden sich die Wassertemperaturen zwischen 85 und 96 Grad Celsius."
      },
      {
        question: "Warum muss ich meinen Kaffee abwiegen bevor ich ihn zubereite?",
        answer: "Die Menge des verwendeten Kaffees beeinflusst den Geschmack. Zu wenig oder zu viel Kaffee kann zu einer unerwünschten Überextraktion oder Unterextraktion führen was deutlich schmeckbar ist. Alleine 0,5 Gramm Kaffee machen einen merkbaren Unterschied im Geschmack."
      },
      {
        question: "Wie beeinflusst die Extraktionszeit den Geschmack des Kaffees?",
        answer: "Die Extraktionszeit variiert je nach Zubereitungsmethode. Halte dich an die empfohlenen Zeiten, um eine optimale Extraktion zu gewährleisten und unerwünschte Aromen sowie oft auch bauchschmerzen bei zu viel gelösten Bitterstoffen zu vermeiden."
      },
      {
        question: "Gibt es eine empfohlene Zubereitungsmethode für Specialty Coffee?",
        answer: "Nein gibt es nicht. Du kannst Speciality Coffee mit einer Mokka Kanne, French Press, Vollautomaten, etc zubereiten."
      }

    ]
  }
]

function classNames(...classes) {
  return classes.filter(Boolean).join(" ")
}

const FaqPage = () => (
  <Layout>
    <Seo title="FAQ" />

    <div className="">
      <div className="max-w-7xl mx-auto py-12 px-4 sm:py-16 sm:px-6 lg:px-8">
        <div className="max-w-3xl mx-auto divide-y-2 divide-caffeinity-brown">
          <h2 className="text-center text-3xl font-extrabold text-caffeinity-green-dark sm:text-4xl">Häufig gestellte
            Fragen
            (FAQ)</h2>
          <p
            className="mt-3 max-w-md mx-auto text-lg text-gray-700 sm:text-xl md:mt-5 md:max-w-3xl border-none">In
            unserem FAQ (Frequently Asked Questions) beantworten wir häufig gestellte Fragen rund um das Thema
            Caffeinity, Kaffee, Zubereitung als auch Anbau & Herkunft. Wenn du weitere Fragen an uns hast sende Sie
            bitte an caffeinity.vienna@gmail.com und wir beatnworten Sie gerne für dich.</p>
          <dl className="mt-6 space-y-6 divide-y divide-caffeinity-brown">
            {faqs.map((faq) => (
              <>
                <h3
                  className="text-center text-xl font-extrabold text-caffeinity-green-dark sm:text-2xl border-none pt-16">{faq.category} </h3>
                <p
                  className="mt-3 max-w-md mx-auto text-lg text-gray-700 sm:text-xl md:mt-5 md:max-w-3xl border-none">{faq.description}</p>

                {faq.questions.map((listQuestion) => (

                  <Disclosure as="div" key={listQuestion.question} className="pt-6">
                    {({ open }) => (
                      <>
                        <dt className="text-lg">
                          <Disclosure.Button
                            className="text-left w-full flex justify-between items-start text-caffeinity-green-dark">
                            <span className="font-bold text-caffeinity-green-dark">{listQuestion.question}</span>
                            <span className="ml-6 h-7 flex items-center">
                          <ChevronDownIcon
                            className={classNames(open ? "-rotate-180" : "rotate-0", "h-6 w-6 transform")}
                            aria-hidden="true"
                          />
                        </span>
                          </Disclosure.Button>
                        </dt>
                        <Disclosure.Panel as="dd" className="mt-2 pr-12">
                          <p className="text-base text-caffeinity-green-dark">{listQuestion.answer}</p>
                        </Disclosure.Panel>
                      </>
                    )}
                  </Disclosure>

                ))}
              </>
            ))}
          </dl>
        </div>
      </div>
    </div>


    <Divider />
    <InstagramFeed />
    <Divider />
    <ContactSection />

  </Layout>
)

export default FaqPage
